import React from 'react'

import AnyLink from '~/components/any-link'

import { getWrapper } from './button-v2.selectors'
import { Content } from './button-v2.styles'

import type { Props } from './button.types'

const Button = (props: Props) => {
  const { className, disabled, htmlType, to, type, onClick, ...otherProps } =
    props

  const Wrapper = getWrapper(type)

  return (
    <Wrapper
      as={to && !disabled ? AnyLink : undefined}
      className={className}
      data-testid="button"
      disabled={disabled}
      to={to}
      type={htmlType || 'button'}
      onClick={onClick}
      {...otherProps}
    >
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

export default Button
