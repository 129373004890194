import { isDesktop, isMobile } from '~/utils/device'

export function scrollToTop() {
  ;(document.querySelector('#scrolling-body') || window).scrollTo(0, 0)
}

// Scroll an element into view, using optional top buffer so that the element
// is the specified number of pixels from top of viewport
export function scrollToElement(
  element: HTMLElement,
  options: {
    topBuffer?: number
    desktop?: boolean
    mobile?: boolean
    behavior?: 'auto' | 'smooth'
  } = {},
) {
  const {
    desktop = true,
    mobile = true,
    topBuffer = 0,
    behavior = 'smooth',
  } = options
  if ((isDesktop() && !desktop) || (isMobile() && !mobile)) return

  let scrollToElement = element

  if (topBuffer !== 0) {
    scrollToElement = document.createElement('div')
    scrollToElement.classList.add('scroll-to-element')
    scrollToElement.style.position = 'relative'
    scrollToElement.style.top = `-${topBuffer}px`
    element.appendChild(scrollToElement)
  }

  scrollToElement.scrollIntoView({
    behavior,
  })

  if (scrollToElement !== element) {
    element.removeChild(scrollToElement)
  }
}
