/*
 * Validation helpers
 */

import valid from 'card-validator'
import { differenceInYears, isFuture, parseISO } from 'date-fns'

import {
  PASSWORD_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  PHONE_NUMBER_DIALING_CODE_REGEX,
  PHONE_NUMBER_VALID_DIALING_CODES,
  ZIP_POSTAL_REGEX_BY_COUNTRY_CODE,
} from '~/config'
import { i18n } from '~/i18n'
import { ageNow } from '~/utils/date'

import { countryStringToCode, countriesAndStates } from './countries'
import { translateErrors } from './error-messages'
import getFeatureFlags from './get-feature-flags'
import {
  AGE_INVALID,
  IN_DISTANT_PAST,
  IN_FUTURE,
  INVALID,
  OUT_OF_RANGE,
  REQUIRED,
  WRONG_CURRENCY,
  INVALID_CHARACTERS,
} from './validations.constants'

import type {
  BillingAddressErrors,
  ErrorCode,
  ShippingAddressErrors,
} from './validations.types'
import type {
  Address,
  CartParticipant,
  CreditCardType,
  GroupMember,
  NewCreditCard,
  ProfileDetailsEdit,
  CartItem,
  Product,
  CodesByProfile,
  AgeRange,
} from '~/types'

export * from './validations.constants'
export * from './validations.types'

const CREDIT_CARD_TYPES = ['american-express', 'discover', 'mastercard', 'visa']

type ValidateCreditCardError = {
  creditCard: {
    nameOnCard?: ErrorCode
    number?: ErrorCode
    year?: ErrorCode
    month?: ErrorCode
    cvc?: ErrorCode
    recaptchaToken?: ErrorCode
  }
} | void

type CardConversionTable = Record<CreditCardType, string>

function convertCardTypes(cardTypes: CreditCardType[]) {
  const conversionTable: CardConversionTable = {
    american_express: 'american-express',
    discover: 'discover',
    master: 'mastercard',
    visa: 'visa',
  }
  return cardTypes.map((cardType) => conversionTable[cardType])
}

export const creditCardErrors = (
  creditCard: NewCreditCard,
  cardTypes: CreditCardType[],
) => {
  const errors = validateCreditCard({
    creditCard: creditCard,
    allowedTypes: cardTypes,
  })
  const translatedErrors = translateErrors(
    errors && errors.creditCard,
    getCreditCardErrorsByKey(),
  )
  return translatedErrors
}

export const addressErrors = (address: Address) => {
  const errors = validateBillingAddress({
    billingAddress: address,
  })

  const translatedErrors = translateErrors(
    errors && errors.billingAddress,
    getAddressErrorsByKey(),
  )

  return translatedErrors
}

export function getAddressErrorsByKey() {
  return {
    street1: {
      [REQUIRED]: i18n.t('pages.payment.address.required_field', {
        name: i18n.t('pages.payment.address.street'),
      }),
    },
    city: {
      [REQUIRED]: i18n.t('pages.payment.address.required_field', {
        name: i18n.t('pages.payment.address.city'),
      }),
    },
    stateProvince: {
      [REQUIRED]: i18n.t('pages.payment.address.required_field', {
        name: i18n.t('pages.payment.address.state'),
      }),
    },
    zipPostal: {
      [REQUIRED]: i18n.t('pages.payment.address.required_field', {
        name: i18n.t('pages.payment.address.zip'),
      }),
      [INVALID]: i18n.t('pages.payment.address.invalid_field', {
        name: i18n.t('pages.payment.address.zip'),
      }),
    },
    phoneNumber: {
      [REQUIRED]: i18n.t('pages.payment.address.required_field', {
        name: i18n.t('pages.payment.address.phone'),
      }),
      [INVALID]: i18n.t('pages.payment.address.invalid_field', {
        name: i18n.t('pages.payment.address.phone'),
      }),
    },
  }
}

export function getCreditCardErrorsByKey() {
  return {
    nameOnCard: {
      [REQUIRED]: i18n.t('pages.payment.credit_card.name_on_card.required'),
    },
    cvc: {
      [INVALID]: i18n.t('pages.payment.credit_card.cvc.invalid'),
      [REQUIRED]: i18n.t('pages.payment.credit_card.cvc.required'),
    },
    number: {
      [INVALID]: i18n.t('pages.payment.credit_card.number.invalid'),
      [OUT_OF_RANGE]: i18n.t('pages.payment.credit_card.number.out_of_range'),
      [REQUIRED]: i18n.t('pages.payment.credit_card.number.required'),
      [WRONG_CURRENCY]: i18n.t(
        'pages.payment.credit_card.number.wrong_currency',
      ),
    },
    month: {
      [REQUIRED]: i18n.t('pages.payment.credit_card.month.required'),
    },
    year: {
      [OUT_OF_RANGE]: i18n.t('pages.payment.credit_card.year.out_of_range'),
      [REQUIRED]: i18n.t('pages.payment.credit_card.year.required'),
    },
  }
}

export function hasErrors({ errors }: { errors?: Record<string, string> }) {
  return errors && Object.keys(errors).length > 0
}

export function validateRequired(item: Record<string, any>, field: string) {
  if (!(item[field] || '').trim())
    return {
      [field]: REQUIRED,
    }
}

export function validateRequiredWithPlaceholder(
  item: Record<string, any>,
  field: string,
  placeholder: string,
) {
  const value = (item[field] || '').trim()
  if (!value || value === placeholder)
    return {
      [field]: REQUIRED,
    }
}

function validateCreditCardNumber(item) {
  const result = valid.number(item.number)
  if (!result.isValid)
    return {
      number: INVALID,
    }
}

function validateCreditCardType(item, allowedTypes?: CreditCardType[]) {
  const { card } = valid.number(item.number)
  if (!typeIsValid(card))
    return {
      number: OUT_OF_RANGE,
    }
  if (!typeMatchesCurrency(card, allowedTypes))
    return {
      number: WRONG_CURRENCY,
    }
}

function typeIsValid(card) {
  return card && CREDIT_CARD_TYPES.includes(card.type)
}

function typeMatchesCurrency(card, allowedTypes) {
  if (!card) return false

  const cardTypes = allowedTypes
    ? convertCardTypes(allowedTypes)
    : CREDIT_CARD_TYPES

  return cardTypes.includes(card.type)
}

function validateCreditCardExpiryYear(item) {
  const required = validateRequiredWithPlaceholder(
    item,
    'year',
    i18n.t('components.date_input.year_placeholder'),
  )

  if (required) return required

  const result = valid.expirationDate(item)
  if (!result.isValid)
    return {
      year: OUT_OF_RANGE,
    }
}

function validateCreditCardCVC(item) {
  const { card } = valid.number(item.number)
  const size = (card && card.code && card.code.size) || 3
  const result = valid.cvv(item.cvc, size)
  if (!result.isValid)
    return {
      cvc: INVALID,
    }
}

export function validateCreditCard({
  allowedTypes,
  creditCard,
}: {
  allowedTypes?: CreditCardType[]
  creditCard: NewCreditCard
}): ValidateCreditCardError {
  const errors = {
    ...validateRequired(creditCard, 'nameOnCard'),
    ...validateCreditCardType(creditCard, allowedTypes),
    ...validateCreditCardNumber(creditCard),
    ...validateRequired(creditCard, 'number'),
    ...validateCreditCardExpiryYear(creditCard),
    ...validateRequiredWithPlaceholder(
      creditCard,
      'month',
      i18n.t('components.date_input.month_placeholder'),
    ),
    ...validateCreditCardCVC(creditCard),
    ...validateRequired(creditCard, 'cvc'),
  }
  if (Object.keys(errors).length)
    return {
      creditCard: errors,
    }
}

// Regex to guarantee e-mail is valid.
const EMAIL_REGEXP = new RegExp(
  "^[a-z0-9._%+-/!#$&'*=?^`{|}~]+@[a-z0-9.-]+\\.[a-z]{2,12}$",
  'i',
)

export function isValidEmail(email: string): boolean {
  return EMAIL_REGEXP.test(email)
}

export const validateSignUp = (
  item: Record<string, any>,
  passwordRequired = false,
  onboarding = false,
) => ({
  ...(onboarding ? {} : validateRequired(item, 'firstName')),
  ...(onboarding ? {} : validateRequired(item, 'lastName')),
  ...validateRequired(item, 'email'),
  ...validateEmail(item),
  ...validateDOB(item),
  ...validateNotInTheDistantPast(item, 'dob'),
  ...validateNotInTheFuture(item, 'dob'),
  ...validateRequired(item, 'dob'),
  ...(passwordRequired ? validatePassword(item) : {}),
})

export function validatePassword(item: { password?: string }) {
  const password = item.password || ''

  if (password.length <= 0) {
    return {
      password: REQUIRED,
    }
  } else if (!PASSWORD_REGEX.test(password)) {
    return {
      password: INVALID,
    }
  }
}

export function validateCurrentPassword(item: { currentPassword: string }) {
  const currentPassword = item.currentPassword || ''

  if (currentPassword.length <= 0) {
    return {
      currentPassword: REQUIRED,
    }
  }
}

export function validateDOB({ dob }: Record<string, any>) {
  return validateMinAge(
    {
      dob,
    },
    13,
  )
}

function validateMinAge({ dob }, age) {
  if (differenceInYears(new Date(Date.now()), parseISO(dob)) < age) {
    return {
      dob: AGE_INVALID,
    }
  }
}

export function validateNotInTheFuture(obj, field) {
  const value = obj[field]

  if (value && isFuture(parseISO(value))) {
    return {
      [field]: IN_FUTURE,
    }
  }
}

export function validateNotInTheDistantPast(obj, field) {
  const value = obj[field]

  if (value && isDistantPast(value)) {
    return {
      [field]: IN_DISTANT_PAST,
    }
  }
}

function isDistantPast(date) {
  return differenceInYears(new Date(), parseISO(date)) > 120
}

export function validateSpecialCharacters(item: object, field: string) {
  if (SPECIAL_CHARACTERS_REGEX.test(item[field])) {
    return {
      [field]: INVALID_CHARACTERS,
    }
  }
}

export function isGroupMemberValid(groupMember: GroupMember) {
  if (arguments.length > 1) {
    throw new Error('invalid args')
  }

  return !!(groupMember.firstName && groupMember.lastName && groupMember.dob)
}

export function isProfileValid(
  profile: CartParticipant,
  groupMember: GroupMember,
) {
  return !!profile.id && isGroupMemberValid(groupMember)
}

// Different from isProfileValid as it considers all the items associated
// with the profile.
export function isParticipantValid(
  products: Product[],
  groupMembers: GroupMember[],
  participant: CartParticipant,
  profileProductAndCartItemCodesByProfile?: CodesByProfile,
) {
  const groupMember = groupMembers.find(
    (groupMember) => participant.id === groupMember.id,
  )
  return (
    !!groupMember &&
    isProfileValid(participant, groupMember) &&
    itemsAreValid(
      participant,
      products,
      groupMember,
      profileProductAndCartItemCodesByProfile,
    )
  )
}

const itemsAreValid = (
  participant: CartParticipant,
  products: Product[],
  groupMember: GroupMember,
  profileProductAndCartItemCodesByProfile: CodesByProfile | null | undefined,
) =>
  participant.items.every((item: CartItem) => {
    const product = products.find((product) => product.id === item.type)

    if (product && product.isUpgrade) return true

    if (item.product === 'addon') {
      return isEligibleForAddOn(
        groupMember,
        product,
        profileProductAndCartItemCodesByProfile,
        item,
      )
    } else {
      return isGroupMemberValidForItem(groupMember, item)
    }
  })

export const isEligibleForAddOn = (
  profile: GroupMember | null | undefined,
  product: Product | null | undefined,
  profileProductAndCartItemCodesByProfile: CodesByProfile | null | undefined,
  item: CartItem,
) => {
  if (!profile || !product || !profileProductAndCartItemCodesByProfile)
    return false

  if (!isGroupMemberValidForItem(profile, item)) return false

  const productCodes = profileProductAndCartItemCodesByProfile[profile.id] || []
  return productCodes.some((productCode) =>
    product.addOnForProducts.includes(productCode),
  )
}

export const isGroupMemberValidForItem = (
  groupMember: GroupMember | null | undefined,
  item: CartItem,
) => {
  if (!groupMember) return false
  const currentAge = ageNow(groupMember.dob)
  const memberEligible =
    isAgeInRange(currentAge, item.ageRange) ||
    isAgeInRange(groupMember.effectiveAge, item.ageRange)

  return item.variant === 'adult' || memberEligible
}

export function isAgeInRange(age: number, ageRange: AgeRange) {
  return age >= ageRange.min && (!ageRange.max || age <= ageRange.max)
}

export function validateEmail({ email }: Record<string, any>) {
  if (email && !isValidEmail(email))
    return {
      email: INVALID,
    }
}

export function validateMinorConsent(
  {
    dob,
    minorConsentApproved,
  }: {
    dob: string
    minorConsentApproved?: boolean
  },
  processMinorConsent: boolean,
) {
  if (ageNow(dob) < 13 && !minorConsentApproved && processMinorConsent)
    return {
      minorConsentApproved: REQUIRED,
    }
}

type ValidateProfileDetailsEditInformationOptions = {
  processMinorConsent?: boolean
}
const validateProfileDetailsEditInformationOptionsDefaults = {
  processMinorConsent: false,
}

export function validateProfileDetailsEditInformation(
  profileDetailsEdit: ProfileDetailsEdit,
  options?: ValidateProfileDetailsEditInformationOptions,
): Record<string, ErrorCode> {
  const { processMinorConsent } = {
    ...validateProfileDetailsEditInformationOptionsDefaults,
    ...options,
  }
  return {
    ...validateRequired(profileDetailsEdit, 'firstName'),
    ...validateRequired(profileDetailsEdit, 'lastName'),
    ...validateNotInTheDistantPast(profileDetailsEdit, 'dob'),
    ...validateNotInTheFuture(profileDetailsEdit, 'dob'),
    ...validateRequired(profileDetailsEdit, 'dob'),
    ...validateEmail(profileDetailsEdit),
    ...validateSpecialCharacters(profileDetailsEdit, 'firstName'),
    ...validateSpecialCharacters(profileDetailsEdit, 'lastName'),
    ...validateMinorConsent(profileDetailsEdit, processMinorConsent),
  }
}

const MIN_PHONE_NUMBER_LENGTH = 8
const PHONE_NUMBER_REGEX = new RegExp(PHONE_NUMBER_DIALING_CODE_REGEX)

export function validatePhoneNumber({ phoneNumber }: { phoneNumber?: string }) {
  if (
    !phoneNumber ||
    phoneNumber.replace(/[- ]/g, '').length < MIN_PHONE_NUMBER_LENGTH
  ) {
    return {
      phoneNumber: REQUIRED,
    }
  }
  const international_dialing_code = (phoneNumber.match(PHONE_NUMBER_REGEX) ||
    {})[1]

  if (!PHONE_NUMBER_VALID_DIALING_CODES.includes(international_dialing_code)) {
    return {
      phoneNumber: INVALID,
    }
  }
}

export function validateState({
  country,
  stateProvince,
}: {
  country: string
  stateProvince: string
}) {
  const states = countriesAndStates().countriesWithStates[country]

  if (!stateProvince && (!country || (states && states.length))) {
    return {
      stateProvince: REQUIRED,
    }
  }
}

export function validateBillingAddress({
  billingAddress,
}: {
  billingAddress: Address
}): BillingAddressErrors | null | undefined {
  const shippingAddressErrors = validateShippingAddress({
    shippingAddress: billingAddress,
  })
  const errors = {
    ...(shippingAddressErrors ? shippingAddressErrors.shippingAddress : {}),
  }
  if (Object.keys(errors).length)
    return {
      billingAddress: errors,
    }
}

function validateZipPostal(address) {
  const countryCode = countryStringToCode(address.country)
  const regex = ZIP_POSTAL_REGEX_BY_COUNTRY_CODE[countryCode]

  if (regex && !new RegExp(regex, 'i').test(address.zipPostal)) {
    return { zipPostal: INVALID }
  } else {
    return {}
  }
}

export function validateShippingAddress({
  shippingAddress,
}: {
  shippingAddress: Address
}): ShippingAddressErrors | null | undefined {
  const { smsOptInEnabled } = getFeatureFlags()
  const phoneNumberErrors = smsOptInEnabled
    ? {}
    : validatePhoneNumber(shippingAddress)

  const errors = {
    ...validateRequired(shippingAddress, 'street1'),
    ...validateRequired(shippingAddress, 'country'),
    ...validateState(shippingAddress),
    ...validateRequired(shippingAddress, 'city'),
    ...validateRequired(shippingAddress, 'zipPostal'),
    ...validateZipPostal(shippingAddress),
    ...phoneNumberErrors,
  }
  if (Object.keys(errors).length)
    return {
      shippingAddress: errors,
    }
}

export const isItemValidForPublicPromotions = (item: CartItem): boolean => {
  return ['adult', 'youth', 'all_ages'].includes(item.variant)
}
