import { Select as BaseSelect } from '@mui/base/Select'
import classNames from 'classnames'
import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react'

import Label from '~/components/text-input-label'

import {
  Container,
  Icon,
  Listbox,
  OptionItem,
  Popup,
  Select,
} from './select-input.styles'

import type { ReactNode } from 'react'
export type OptionObject = {
  value: string | number
  label: string | number
  disabled?: boolean
}
export type Option = string | number | OptionObject

export type Props = {
  disabled?: boolean
  onChange: (...args: Array<any>) => any
  hasError?: boolean
  label?: ReactNode
  name?: string
  value?: string | number
  defaultValue?: string | number
  id?: string
  options: Option[]
  className?: string
  variant?: 'primary' | 'secondary'
  fullWidth?: boolean
}

const SelectInput = ({
  id,
  disabled,
  onChange,
  hasError,
  label,
  name,
  value,
  defaultValue,
  options,
  className,
  variant,
  fullWidth,
}: Props) => {
  const [width, setWidth] = useState<number | undefined>(undefined)
  const selectRef = useRef<HTMLButtonElement>(null)

  useLayoutEffect(() => {
    setWidth(selectRef.current?.offsetWidth)
  }, [selectRef.current])

  const optionObjects = options.map((option: Option) => {
    let value
    let label
    let disabled = false

    if (typeof option === 'object') {
      ;({ value, label, disabled } = option)
    } else {
      value = option
      label = option
    }

    return {
      value,
      disabled,
      label,
    }
  })
  const selectedOption =
    optionObjects.find((option) => option.value == (value ?? defaultValue)) ??
    optionObjects[0]
  const fieldClass = classNames('amp-select-input', className)

  const renderLabel = () => {
    if (!label) return null
    return (
      <Label className="label" disabled={disabled} hasError={hasError}>
        {label}
      </Label>
    )
  }

  const renderOption = (option: OptionObject) => (
    <OptionItem
      key={option.value}
      value={option.value}
      disabled={option.disabled}
      $disabled={option.disabled}
      $selected={selectedOption && option === selectedOption}
    >
      {option.label}
    </OptionItem>
  )

  return (
    <Container className={fieldClass}>
      {renderLabel()}
      <BaseSelect
        id={id}
        ref={selectRef}
        data-testid={id}
        disabled={disabled}
        onChange={(_event, value) =>
          onChange({ target: { value: value?.toString() } })
        }
        value={selectedOption?.value ?? ''}
        slots={{
          root: forwardRef((props, ref) => {
            const { ownerState, ...other } = props
            return (
              <Select
                {...other}
                name={name}
                ref={ref as React.Ref<HTMLButtonElement>}
                $variant={variant}
                $hasError={hasError}
                $fullWidth={fullWidth}
              >
                {other.children}
                <Icon
                  glyph={other['aria-expanded'] ? 'chevron-up' : 'chevron-down'}
                  $disabled={disabled}
                />
              </Select>
            )
          }),
          listbox: forwardRef((props, ref) => {
            const { ownerState, ...other } = props
            return (
              <Listbox
                {...other}
                ref={ref as React.Ref<HTMLUListElement>}
                $width={width}
              />
            )
          }),
          popup: forwardRef((props, ref) => {
            const { ownerState, ...other } = props
            return <Popup {...other} ref={ref as React.Ref<HTMLDivElement>} />
          }),
        }}
      >
        {optionObjects.map(renderOption)}
      </BaseSelect>
    </Container>
  )
}

export default SelectInput
