import {
  PlainButton,
  PrimaryButton,
  SecondaryButton,
  InlineButton,
} from './button-v2.styles'

import type { ButtonType } from './button.types'

export const getWrapper = (type: ButtonType | ButtonType[]) => {
  switch (type) {
    case 'plain':
      return PlainButton
    case 'unstyled':
      return InlineButton
    case 'outline':
      return SecondaryButton
    default:
      return PrimaryButton
  }
}
